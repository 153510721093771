import Vue from "vue"
import Loading from '@/components/widgets/Loading.vue'
import SortedTablePlugin from "vue-sorted-table"
import Paginate from "@/components/widgets/pagination.vue";

Vue.use(SortedTablePlugin, {
  ascIcon: '<i class="mdi mdi-arrow-down"></i>',
  descIcon: '<i class="mdi mdi-arrow-up"></i>'
})

export default {
  components: {
    Loading,
    Paginate
  },
  data: function() {
    return {
      loading: false,
      busy: false,
      sortBy: 'name',
      perPage: 8,
      currentPage: 1,
      currentData: {},
      sortDesc: false,
      sortByFormatted: true,
      filterByFormatted: true,
      sortable: true,
      position: null,
      keyword: '',
      fields: [
        {
          key: 'fullname',
          label: 'ข้อมูล',
          thClass: 'font-weight-bold text-xs-1/2', 
          tdClass: 'td-top text-xs-1/2'
        },
        {
          key: 'username',
          label: 'รหัสผู้ใช้งาน',
          sortable: true,
          thClass: 'font-weight-bold text-xs-1/2', 
          tdClass: 'text-gray-400 td-top text-xs-1/2'
        },
        { 
          key: 'position', 
          label: 'ตำแหน่งงาน', 
          sortable: true, 
          thClass: 'font-weight-bold text-xs-1/2', 
          tdClass: 'text-gray-400 td-top text-xs-1/2' 
        },
        {
          key: 'created_at',
          label: 'วันที่สร้าง',
          sortable: true,
          thClass: 'font-weight-bold text-xs-1/2', 
          tdClass: 'text-gray-400 td-top text-xs-1/2'
        },
        {
          key: 'permissions',
          label: '#',
          thClass: 'font-weight-bold text-xs-1/2', 
          class: 'text-center td-top text-xs-1/2'
        }
      ],
      items: []
    }
  },
  mounted() {
    this.onInitUsers(1)
  },
  computed: {
    rows() {
      return this.items.length
    }
  },
  methods: { 
    onPermitted(item) {
      this.$emit('tabIndex', { 
        data: item,
        index: 1
      })
    },
    async onInitUsers(currentPage) {
      this.busy = true
      try {
        const page = currentPage ? currentPage : 1
        const url = `${Vue.prototype.$host}/api/v1/users?page=${page}&keyword=${this.keyword}`
        const param = {
          keyword: this.keyword,
          url: url
        }
        const result = await this.$store.dispatch('Users/search', param, { root: true })
        this.busy = false

        if (result) {
          this.currentData = result.data;
          this.items = result.data.data
        } else {
          this.items =  []
        }
      } catch (error) {
        this.onExceptionHandler()
        this.busy = false
        return []
      }
    }
  }
}
