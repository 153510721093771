import UsersList from './widgets/widget-users-list'
import Privilege from './widgets/widget-privilege'
export default {
  name: 'permited',
  components: {
    UsersList,
    Privilege
  },
  data() {
    return {
      tabIndex: 0,
      name: ''
    }
  },
  methods: {
    onTabIndexChange(event) {
      this.name = event.data?.name?? ''
      this.tabIndex = event.index
    }
  }
}
