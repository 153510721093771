import Loading from '@/components/widgets/Loading.vue'
export default {
  components: {
    Loading
  },
  props: {
    name: {
      type: String
    }
  },
  data() {
    return {
      tabIndex: 1,
      loading: false,
      show: false,
      menus: [],
      role: 'administrator',
      menu: [
        { text: 'จัดการสิทธิ์', value: 'profile', create: true, update: false, delete: false },
        { text: 'จัดการผู้ใช้งาน', value: 'password', create: false, update: true, delete: false },
        { text: 'ใบสั่งซื้อสินค้า', value: 'permissions', create: false, update: false, delete: true },
        { text: 'ใบส่งคืนจากลูกค้า', value: 'purchasae-order', create: false, update: false, delete: true },
        { text: 'ใบรับเข้าสินค้า', value: 'return-customer', create: false, update: true, delete: false },
        { text: 'ใบสั่งขายสินค้า', value: 'other-order', create: false, update: true, delete: false },
        { text: 'ใบส่งคืนสินค้าให้ผู้ขาย', value: 'sale-order', create: true, update: false, delete: false },
        { text: 'ใบเบิกสินค้าอื่นๆ', value: 'return-seller', create: true, update: false, delete: false },
        { text: 'ใบสั่งผลิต', value: 'requisition', create: true, update: false, delete: false },
        { text: 'นำเข้าสินค้า', value: 'production', create: true, update: false, delete: false },
        { text: 'นำสินค้าออก', value: 'stockin', create: false, update: false, delete: false },
        { text: 'Batch', value: 'stockout', create: false, update: false, delete: false },
        { text: 'ย้ายสินค้า', value: 'transpose', create: false, update: false, delete: false },
        { text: 'ปรับปรุงยอดสินค้า', value: 'adjustment', create: false, update: false, delete: false },
        { text: 'ใบวางบิล', value: 'billing', create: true, update: true, delete: false },
        { text: 'รับชำระหนี้', value: 'payment', create: true, update: false, delete: false }
      ],
      reports: [
        { text: 'รายงานสินค้าคงเหลือ', value: 'balance', create: false, update: true, delete: false },
        { text: 'รายงานใบสั่งสินค้าขาเข้า พร้อมสถานะล่าสุด', value: 'inbound', create: true, update: false, delete: false },
        { text: 'รายงานใบสั่งสินค้าขาออก พร้อมสถานะล่าสุด', value: 'outbound', create: false, update: false, delete: false },
        { text: 'รายงานใบสั่งผลิต พร้อมสถานะล่าสุด', value: 'manufacture', create: false, update: true, delete: false },
        { text: 'รายงานลูกหนี้คงค้างและอายุหนี้', value: 'ar-aging', create: false, update: false, delete: true },
        { text: 'รายงานความเคลื่อนไหวสินค้า', value: 'movement', create: false, update: false, delete: true },
        { text: 'รายงานลูกหนี้คงค้างแยกตามพนักงานขายและกลุ่มลูกหนี้', value: 'ar-sale', create: false, update: false, delete: false },
        { text: 'รายงานการกำหนดชำระหนี้', value: 'ar-payment', create: false, update: false, delete: true },
        { text: 'รายงานยอดขายรวมและแยกกลุ่ม', value: 'sale', create: false, update: false, delete: false },
        { text: 'รายงานค่าใช้จ่าย', value: 'spending', create: false, update: false, delete: false },
        { text: 'รายงานรายรับรายจ่ายประจำวัน', value: 'revenue', create: true, update: false, delete: false },
        { text: 'รายงานการจองสินค้า', value: 'reservation', create: true, update: false, delete: false },
        { text: 'รายงานรับชำระหนี้', value: 'repay', create: true, update: false, delete: false },
        { text: 'รายงานคืนสินค้าและลดหนี้', value: 'credit-note', create: false, update: false, delete: false },
        { text: 'รายงานแสดงยอดภาษีหัก ณ ที่จ่าย', value: 'withholding-tax', create: false, update: false, delete: false },
        { text: 'รายงานวิเคราะห์ต้นทุนกำไรขาดทุน', value: 'profitability', create: false, update: false, delete: false }
      ],
      masters: [
        { text: 'ข้อมูลสินค้า', value: 'products', create: true, update: false, delete: false },
        { text: 'ข้อมูลสาขา', value: 'branch', create: true, update: false, delete: false },
        { text: 'ข้อมูลลูกค้า', value: 'customer', create: false, update: false, delete: false },
        { text: 'ข้อมูลผู้ขาย', value: 'supplier', create: true, update: false, delete: false },
        { text: 'ข้อมูลเครื่องจักรและที่เก็บสินค้า', value: 'location', create: false, update: false, delete: true }
      ]
    }
  },
  watch: { },
  mounted() {
    let name = this.$route.params
    if (name?.name) {
      this.name = name.name
    }
  },
  methods: {
    onSubmitForm() {
      this.$swal({
        width: "29rem",
        padding: '1.825em',
        html: '<div class="d-flex flex-row align-items-center">' +
          '<i class="fa fa-2x mr-3 fa-exclamation-triangle text-info"></i>'+
          '<div class="d-flex flex-column">'+
          '<span class="font-bold text-base py-2.5 text-left">แจ้งเตือน</span>'+
          `<span class="text-base text-left text-gray-500">บันทึกข้อมูลเรียบร้อยแล้ว</span>`+
          '</div>'+
        '</div>',
          allowOutsideClick: false,
          focusConfirm: false,
          customClass: {
            popup: 'swal2-custom-rounded',
            closeButton: 'text-3xl swal2-custom-close',
            actions: 'justify-content-end',
            contant: 'd-flex flex-row justify-content-around px-2',
            title: 'd-none',
            confirmButton: "btn btn-info py-3 rounded-lg text-white font-prompt-light text-sm font-normal",
            cancelButton: 'btn btn-google py-3 rounded-lg text-white font-prompt-light text-sm font-normal'
          },
          showCancelButton: false,
          confirmButtonText: "ตกลง",
          cancelButtonText: "ยกเลิก",
          showCloseButton: true,
      })
    },
    onClose() {
      this.$emit('tabIndex', {
        index: 0
      })
    },
    resetAactiveMenu() {
      for (let index = 0; index < this.menu.length; index++) {
        const element = this.menu[index]
        let elr = this.$refs[`checkbox-menutext-${element.value}`][0]
        elr.classList.remove('el-active')
      }
      for (let index = 0; index < this.masters.length; index++) {
        const element = this.masters[index]
        let elr = this.$refs[`checkbox-menutext-${element.value}`][0]
        elr.classList.remove('el-active')
      }
    }
  }
}